<template>
  <div>
    <div
      class="card mb-3 profile-card "
      style="width: 100%"
      v-loading="loading"
      element-loading-text="Loading..."
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <div class="card-header bg-light">
        <h3 class="title fw-normal mb-0">Personal Information</h3>
      </div>
      <el-form ref="form" :model="updatePersonalInformation">
        <!--<el-row>
          <el-col :span="18">
            <div class="card-body">
              <div class="pt-1 p-4 d-flex">-->
        <el-row>
          <el-col :xl="8" :lg="8" :md="8" :sm="12" :xs="12">
            <el-form-item label="First Name" class="mr-1 ml-1 requiredLables">
              <el-input
                v-model="updatePersonalInformation.first_name"
              ></el-input>
              <p
                class="error"
                v-if="
                  getUpdateProfileErrors && getUpdateProfileErrors.first_name
                "
              >
                {{ getUpdateProfileErrors.first_name }}
              </p>
            </el-form-item>
          </el-col>
          <el-col :xl="8" :lg="8" :md="8" :sm="12" :xs="12">
            <el-form-item label="Last Name" class="mr-1 ml-1 requiredLables">
              <el-input
                v-model="updatePersonalInformation.last_name"
              ></el-input>
              <p
                class="error"
                v-if="
                  getUpdateProfileErrors && getUpdateProfileErrors.last_name
                "
              >
                {{ getUpdateProfileErrors.last_name }}
              </p>
            </el-form-item>
          </el-col>
          <el-col :xl="8" :lg="8" :md="8" :sm="12" :xs="12">
            <el-form-item label="Title" class="mr-1 ml-1">
              <el-input  v-model="updatePersonalInformation.title"></el-input>
              <p
                class="error"
                v-if="getUpdateProfileErrors && getUpdateProfileErrors.title"
              >
                {{ getUpdateProfileErrors.title }}
              </p>
            </el-form-item>
          </el-col>
          <el-col :xl="8" :lg="8" :md="8" :sm="12" :xs="12">
            <el-form-item label="Phone" class="mr-1 ml-1 requiredLable">
              <el-input v-model="updatePersonalInformation.phone"></el-input>
              <p
                class="error"
                v-if="getUpdateProfileErrors && getUpdateProfileErrors.phone"
              >
                {{ getUpdateProfileErrors.phone }}
              </p>
            </el-form-item>
          </el-col>

          <el-col :xl="8" :lg="8" :md="8" :sm="12" :xs="12">
            <el-form-item label="Address" class="ml-1 mr-1">
             
              <template >
                <el-input
                  v-model="updatePersonalInformation.address"
                ></el-input>
              </template>
              <p
                class="error"
                v-if="getUpdateProfileErrors && getUpdateProfileErrors.address"
              >
                {{ getUpdateProfileErrors.address }}
              </p>
            </el-form-item>
          </el-col>
          <el-col
            :xl="8"
            :lg="8"
            :md="8"
            :sm="12"
            :xs="12"
            v-if="
              getActiveWorkspace.company_id &&
              getActiveWorkspace.company_id != '0'
            "
          >
            <!-- <el-form-item label="Company Name" class="ml-1 mr-1" prop="company_name">
                  <el-input
                    :readonly="checkReadonly()"
                    v-model="brandingInfo.name"
                  ></el-input>
                  <p
                    class="error"
                    v-if="
                      getUpdateProfileErrors && getUpdateProfileErrors.company_name
                    "
                  >
                    {{ getUpdateProfileErrors.company_name }}
                  </p>
                </el-form-item> -->
            <el-form-item label="Email Address" class="ml-1 mr-1">
              <el-input disabled v-model="getAuthenticatedUser.email">
                <p>{{ this.getAuthenticatedUser.email }}</p></el-input
              >
            </el-form-item>
          </el-col>
          <!-- <el-col :xl="12" :lg="12" :md="12" :sm="24" :xs="24">
                 <el-form-item label="Email Address" class="ml-1 mr-1">
                  <el-input  disabled v-model="getAuthenticatedUser.email"> <p> {{ this.getAuthenticatedUser.email }} </p></el-input>
                </el-form-item>
                </el-col> -->
        </el-row>
        <!--</div>-->
        <!-- </div>
          </el-col>
        </el-row>-->
        <p
          class="error"
          v-if="getUpdateProfileErrors && getUpdateProfileErrors.critical_error"
        >
          {{ getUpdateProfileErrors.critical_error }}
        </p>
        <div class="card-footer bg-white">
          <el-form-item class="mb-0 p-2">
            <el-button @click="getOldData" plain>Cancel</el-button>
            <el-button
              type="primary"
              @click="checkProfileData"
              >Save Changes</el-button
            >
          </el-form-item>
        </div>
      </el-form>
    </div>
    <!-- <button @click="generateUrl">OutLook Oauth</button> -->
  </div>
</template>
<script>
import { bus } from "../../main";
import { mapGetters } from "vuex";
import config from "./../../config/app";
import auth from './../../helpers/microsoftAuth';
import axios from '@/config/axios';
export default {
  name: "PersonalInformation",
  data() {
    return {
      loading: false,
      updatePersonalInformation: {
        first_name: "",
        last_name: "",
        title: "",
        phone: "",
        address: "",
        company_name: "",
        /* zip_code:"",
        country:"",
        state:"",
        city:""*/
      },
      brandingInfo: {},
      isSubmit: false,
      outlookUser:{},
      error:''
    };
  },
  computed: {
    ...mapGetters("errors", ["getErrors"]),
    ...mapGetters("auth", [
      "getAuthenticatedUser",
      "getUpdateProfileStatus",
      "getUpdateProfileErrors",
      "getActiveWorkspace",
      "getUserType",
      "getWorkspaceList",
    ]),
    ...mapGetters("company", [
      "getCompanyDetails",
      "getUpdateCompany",
    ]),
  },
  mounted() {
    let isOwner = !this.checkReadonly();
    this.$store.commit("auth/setUpdateProfileErrors", null, { root: true });
    this.setUserInfo();
    if (
      this.getActiveWorkspace.company_id &&
      this.getActiveWorkspace.company_id != "0" &&
      isOwner
    ) {
      this.getCompanyInfo();
    }
  },
  async created() {
    // Basic setup of MSAL helper with client id, or give up
    if (config.AZURE_APP_ID && config.AZURE_APP_SECRET) {
      auth.configure(config.AZURE_APP_ID, config.AZURE_APP_SECRET, false)
      // Restore any cached or saved local user
      this.outlookUser = auth.user();
    } else {
      this.error = 'outlook_CLIENT_ID is not set, the app will not function! 😥'
    }
  },
  methods: {
    async generateUrl()
    {
//       const parameters = `client_id=${config.AZURE_APP_ID}&redirect_uri=${config.AZURE_OAUTH_REDIRECT_URI}&response_type=code&scope=${config.AZURE_SCOPES}`;
// const URI = `${config.AZURE_AUTHORITY}oauth2/v2.0/authorize?${parameters}`;
//     },
//     async doLogin() {
      try {
        await auth.login();
        // this.$emit('loginComplete')
      } catch (err) {
        console.log("generateUrl", err)
        // this.error = err.toString()
      }
    },
   async setUserInfo() {
      let userData = await axios.get('/getUserProfile')
      let userDeatails = userData.data.data
      // if(userData && userData.data && userData.data.data){
      //   this.getAuthenticatedUser = { ...this.getAuthenticatedUser, ...userData.data.data };
      // }
      this.getAuthenticatedUser.first_name = userDeatails.first_name
      this.getAuthenticatedUser.last_name = userDeatails.last_name
      this.getAuthenticatedUser.title = userDeatails.title
      this.getAuthenticatedUser.phone = userDeatails.phone
      this.getAuthenticatedUser.address = userDeatails.address
      this.updatePersonalInformation.first_name =
        this.getAuthenticatedUser.first_name;
      this.updatePersonalInformation.last_name =
        this.getAuthenticatedUser.last_name;
      this.updatePersonalInformation.title = this.getAuthenticatedUser.title;
      this.updatePersonalInformation.phone = this.getAuthenticatedUser.phone;
      this.updatePersonalInformation.address = this.updatePersonalInformation.address ? this.updatePersonalInformation.address : this.getAuthenticatedUser.address;
      this.updatePersonalInformation.company_name = this.getAuthenticatedUser.company_name;
    },
    async checkProfileData() {
      await this.$store.commit("auth/setUpdateProfileErrors", null);
      if(!this.updatePersonalInformation.first_name.trim().length || !this.updatePersonalInformation.last_name.trim().length){
        !this.updatePersonalInformation.first_name.trim().length ? this.$message.error('First Name is Required') : this.$message.error('Last Name is Required')
      }else if (this.updatePersonalInformation.title && this.updatePersonalInformation.title !== "" && !/^[a-zA-Z][a-zA-Z0-9\s]*$/.test(this.updatePersonalInformation.title)) {
          this.updatePersonalInformation.title = '';
          this.$message.error('The title must include both letters and numbers. Please enter a title in the proper format.');
      }else{

        if(!this.updatePersonalInformation.phone.trim().length)
        {
          this.$message.error('Phone Number is Required') 
        }
        else{
        const re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;

      if(re.test(this.updatePersonalInformation.phone))
      {
        this.onSubmit();
      }
      else{
        this.$message.error('Invalid phone number')
      }
    } 
      }
    },
    resetForm() {
      this.$refs.form.resetFields();
    },
    async onSubmit() {
      try {
        this.loading = true;
        await this.$store.dispatch(
          "auth/updateUserProfile",
          this.updatePersonalInformation
        );
        this.setUserInfo();
        if (this.getUpdateProfileStatus) {
         // let isOwner = !this.checkReadonly();
          // if (this.getActiveWorkspace && this.getActiveWorkspace.plan_type && (this.getActiveWorkspace.plan_type == "BUSINESS" && isOwner) || (this.getActiveWorkspace.plan_type == "INDIVIDUAL")) {
          //   this.updateCompanyDetails();
          // }
          // else {
            this.$notify.success({
              title: "Success",
              message: "User Personal Information updated successfully",
            });
          //}
        } else {
          this.$notify.error({
            title: "Error",
            message: "Error in updating User Personal Information",
          });
        }
        this.loading = false;
      } catch (err) {
        console.log("onSubmit", err);
      }
    },
    getOldData() {
      let isOwner = !this.checkReadonly();
      this.updatePersonalInformation.first_name =
        this.getAuthenticatedUser.first_name;
      this.updatePersonalInformation.last_name =
        this.getAuthenticatedUser.last_name;
        this.updatePersonalInformation.address = this.getAuthenticatedUser.address;
      this.updatePersonalInformation.title = this.getAuthenticatedUser.title;
      this.updatePersonalInformation.phone = this.getAuthenticatedUser.phone;
      this.updatePersonalInformation.company_name =
        this.getAuthenticatedUser.company_name;
      if (
        this.getActiveWorkspace.company_id &&
        this.getActiveWorkspace.company_id != "0" &&
        isOwner
      ) {
        this.getCompanyInfo();
      } else {
        this.updatePersonalInformation.address =
        this.updatePersonalInformation.address ? this.updatePersonalInformation.address : this.getAuthenticatedUser.address;
      }
    },
    async updateCompanyDetails() {
      this.loading = true;
      try {
        if (this.brandingInfo && this.brandingInfo.name) {
          delete this.brandingInfo.name;
        }
        await this.$store.dispatch("company/updateCompany", this.brandingInfo);
        if (this.getUpdateCompany.success) {
          this.currentWorkspace = this.getActiveWorkspace;
          // await this.$store.dispatch(
          //   "auth/fetchUsersWithEmail",
          //   this.getAuthenticatedUser.email
          // );
          if (this.getWorkspaceList) {
            let userWorkspaceList = this.getWorkspaceList;
            let hasIndividual = this.getAuthenticatedUser.workspace.findIndex(
              (u) => u.plan_type == "INDIVIDUAL"
            );
            if (hasIndividual != -1) {
              let individualWorkspace = {};
              if (
                this.getAuthenticatedUser.workspace[hasIndividual].company_id
              ) {
                this.$set(
                  individualWorkspace,
                  "_id",
                  "" +
                    this.getAuthenticatedUser.workspace[hasIndividual]
                      .company_id
                );
              } else {
                this.$set(individualWorkspace, "_id", "0");
              }
              this.$set(individualWorkspace, "name", "INDIVIDUAL");
              userWorkspaceList[hasIndividual]["company_id"] =
                individualWorkspace;
            }
            bus.$emit("workspaceList", userWorkspaceList);
          }
          this.getCompanyInfo();
          this.loading = false;
          this.$notify.success({
            title: "Success",
            message: "User Personal Information updated successfully",
          });
        } else {
          this.$notify.error({
            title: "Error",
            message: this.getUpdateCompany.message,
          });
        }
      } catch (e) {
        this.loading = false;
        this.$notify.error({
          title: "Error",
          message: e,
        });
      }
    },
    async getCompanyInfo() {
      this.loading = true;
      try {
        // await this.$store.dispatch(
        //   "company/fetchCompany",
        //   this.getActiveWorkspace.company_id
        // );
        if (this.getCompanyDetails) {
          this.brandingInfo = this.getCompanyDetails;
        }
        this.loading = false;
      } catch (e) {
        this.loading = false;
        this.$notify.error({
          title: "Error",
          message: e,
        });
      }
    },

    checkReadonly() {
      if (this.getUserType != undefined) {
        return !(
          this.getUserType.name == "OWNER" || this.getUserType.name == "ADMIN"
        );
      } else {
        return true;
      }
    },
  },
  beforeDestroy() {
    this.$store.commit("company/setUpdateCompany",null,
     {
      root: true,
    }
    );
  },
};
</script>
<style scoped>
.requiredLables:before {
  content:"*";
  color:red;
  position: absolute;
   margin-left: 75px;
 margin-top:9px;
}
.requiredLable:before {
  content:"*";
  color:red;
  position: absolute;
   margin-left: 45px;
 margin-top:9px;
}

.profile-card{
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    transform : scale(1.0);
}
.el-button.is-plain:hover {
  background: var(--primary-contrast-color);
  border-color: var(--primary-color);
  color: var(--primary-color);
}
</style>